.tagContainer {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag {
  padding: 0.5rem;
  margin: 1rem 1rem 1rem 0;
  border: none;
  border-radius: 10px;
  background-color: #198754;
  color: white;
}

.addTagBtn {
  font-weight: bold;
  margin-left: 1rem;
  padding: 0.3rem 0.8rem;
  border-radius: 50px;
  border: 2px solid #198754;
  background-color: white;
  color: #198754;
}

.removeTagBtn {
  margin: 0 0.5rem 0 1rem;
  padding: 0;
  /* border-radius: 50px; */
  border: none;
  color: white;
  background-color: transparent;
  font-weight: bold;
}
